import { render, staticRenderFns } from "./SettlementFormModal.vue?vue&type=template&id=76c66a12&scoped=true"
import script from "./SettlementFormModal.vue?vue&type=script&lang=js"
export * from "./SettlementFormModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76c66a12",
  null
  
)

export default component.exports